'use client';

import { Button } from '@axiom-ui/react/button';
import { Body, Heading } from '@axiom-ui/react/typography';

import { Logo } from '@/modules/shared/ui/team-components/logo';
import { PageFeedback } from '@/modules/shared/ui/team-components/page-feedback';

export default function Error() {
  const previousPageClick = () => {
    window.history.back();
  };
  return (
    <PageFeedback>
      <PageFeedback.Info>
        <PageFeedback.Body>
          <Heading variant="bold" size="md" className="text-pure">
            Oops, ocorreu um erro!
          </Heading>
          <Body size="xs">
            Não se preocupe, nossa equipe já foi notificada e está trabalhando
            para corrigir esse erro o quanto antes, pedimos desculpas pelo
            transtorno. Por favor, tente atualizar sua página em alguns minutos.
          </Body>
        </PageFeedback.Body>
        <PageFeedback.Actions>
          <Button variant="ghost" onClick={previousPageClick}>
            Voltar à página anterior
          </Button>
          <Button asChild>
            <a href="https://suporte.iclinic.com.br/perguntas-frequentes">
              Acessar a central
            </a>
          </Button>
        </PageFeedback.Actions>
      </PageFeedback.Info>
      <Logo />
    </PageFeedback>
  );
}
